const ROUTES = {
  ROOT: '/',
  LOGIN: '/login',
  HOME: '/home',
  REGISTER: '/register',
  REGISTERGU: '/register-guest-user',
  CARDFORM: '/mercadopago',
  CHECKOUT: '/checkout',
  CHECKOUT_CONFIRM: '/checkout_confirm',
  CONFIRMATION: '/confirmation',
  INVOICE: '/invoice',
  WELCOME: '/welcome',
  PROFILE: '/profile',
  PROFILE_CREATE: '/profile/create',
  GREET: '/greet',
  PROFILE_EDIT: '/profile/edit',
  PROFILE_EDIT_COUNTRIES: '/profile/edit/countries',
  PROFILE_EDIT_CATEGORIES: '/profile/edit/categories',
  PROFILE_EDIT_GOAL: '/profile/edit/goal',
  GAME: '/game',
  TEST: '/test',
  RESULTS: '/results',
  USERS: '/users',
  POSTS: '/posts',
  RANKING: '/ranking',
  TRADING: '/trading',
  LOGOUT: '/logout',
  CONGRATULATIONS: '/congratulations',
  TOS: '/tos',
  TDP: '/tdp',
  RECOVER_PASSWORD: '/recover-password',
  CHANGE_PASSWORD: '/reset/password',
  TUTORIAL: '/tutorial',
  ZERO_FUNDS: '/zero-funds',
  PRODUCTS: '/products',
  PROMOTION: '/promotion',
  GROUPS: '/groups',
  GROUPS_CREATE: '/groups/create',
  NEWS: '/news',
  COURSES: '/courses',
  BUDGET: '/budget',
  BUDGET_CREATE: '/budget/create',
  MISSIONS: '/missions',
  WORLDS: '/worlds',
  ALFI_BANK: '/alfi-bank',
  ALFI_BANK_PRODUCT_DETAIL: '/alfi-bank-product-detail',
  TEAM: '/team',
  ASSIGN_A_TEAM: '/team/assign',
  // PRODUCTS_LANDING: '/products/landing',
  COURSES_V3: '/coursesv3',
  COURSES_V3_MY_CLASSES: '/my_classes',
  FOROS_V3: '/foros/all',
  BUDGET_V3: '/budgetv3',
  BUDGET_V3_CREATE: '/budgetv3/create',
  BUDGET_V3_DETAILS: '/budgetv3/details',
  METAS_V3: '/metasv3',
  META_V3_CREATE: '/metasv3/create',
  META_V3_DETAILS: '/metasv3/details',
};

export default ROUTES;
