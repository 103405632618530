/* eslint-disable max-len */
import React, { Fragment, lazy, Suspense } from 'react';
import { Switch, Route } from 'react-router-dom';
import PrivateRoute from '@components/complex/PrivateRoute';
import Logout from '@screens/Logout';
import ROUTES from '@constants/routes';
import GoogleAnalytics from '@components/complex/GoogleAnalytics';
import { showRegister } from '@utils/envValidations';

import './styles.scss';

const Login = lazy(() => import('@screens/Login/index'));
const Home = lazy(() => import('@screens/Home/index'));
const RecoverPassword = lazy(() => import('@screens/RecoverPassword'));
const ChangePassword = lazy(() => import('@screens/ChangePassword'));
const Register = lazy(() => import('@screens/Register'));
const RegisterGU = lazy(() => import('@screens/RegisterGuestUser'));
const Congratulations = lazy(() => import('@screens/Congratulations'));
const ZeroFunds = lazy(() => import('@screens/ZeroFunds'));
const Tutorial = lazy(() => import('@screens/Tutorial'));
const Team = lazy(() => import('@screens/Team'));
const AssignTeam = lazy(() => import('@screens/Team/AssignTeam'));
const Game = lazy(() => import('@screens/Game'));
const Test = lazy(() => import('@screens/Test'));
const ProfileCreate = lazy(() => import('@screens/Profile/Create'));
const Ranking = lazy(() => import('@screens/Ranking'));
const Results = lazy(() => import('@screens/Results'));
const AlfiBank = lazy(() => import('@screens/Products'));
const AlfiBankProductDetail = lazy(() => import('@screens/Products/product-page.js'));
const ProfileEditCategories = lazy(() => import('@screens/Profile/Edit/components/Categories'));
const ProfileEditCountries = lazy(() => import('@screens/Profile/Edit/components/Countries'));
const ProfileEdit = lazy(() => import('@screens/Profile/Edit'));
const Profile = lazy(() => import('@screens/Profile'));
const Tos = lazy(() => import('@screens/Tos'));
const Tdp = lazy(() => import('@screens/Tdp'));
const Groups = lazy(() => import('./screens/Groups'));
const GroupsDetails = lazy(() => import('./screens/GroupsDetails/ContainerGroupsDetails'));
const GroupsCreate = lazy(() => import('./screens/GroupsCreate/GroupsCreateContainer'));
const Courses = lazy(() => import('./screens/Courses'));
const CoursesV3 = lazy(() => import('./screens/CoursesV3'));
const CoursesV3Details = lazy(() => import('./screens/CoursesV3/screens/Details'));
const LessonV3Details = lazy(() => import('./screens/CoursesV3/components/unitLesson'));
const CoursesV3Test = lazy(() => import('./screens/CoursesV3/components/unitTest'));
const MyClassesV3 = lazy(() => import('./screens/CoursesV3/screens/MyClasses'));
const ForosV3 = lazy(() => import('./screens/Foros/index'));
const BudgetV3 = lazy(() => import('./screens/BudgetV3/index'));
const BudgetV3Create = lazy(() => import('./screens/BudgetV3/components/containers/CreateBudget'));
const BudgetV3Details = lazy(() => import('./screens/BudgetV3/components/containers/BudgetDetails'));
const MetasV3 = lazy(() => import('./screens/Meta/index'));
const MetasV3Create = lazy(() => import('./screens/Meta/components/containers/CreateMeta'));
const MetasV3Details = lazy(() => import('./screens/Meta/components/containers/MetaDetails'));
const CoursesDetails = lazy(() => import('./screens/CoursesDetails'));
const CoursesTest = lazy(() => import('./screens/CoursesTest'));
const Budget = lazy(() => import('./screens/Budget'));
const BudgetCreate = lazy(() => import('./screens/Budget/BudgetCreate'));
const Missions = lazy(() => import('./screens/Missions'));
// const Worlds = lazy(() => import('./screens/Worlds'));

function App() {
  return (
    <Fragment>
      <Suspense fallback={<div>.</div>}>
        <Switch>
          <Route path={ROUTES.ROOT} component={Login} exact />
          <Route path={ROUTES.LOGIN} component={Login} />
          <Route path={ROUTES.TOS} component={Tos} exact />
          <Route path={ROUTES.TDP} component={Tdp} exact />
          <Route path={ROUTES.CHANGE_PASSWORD} component={ChangePassword} />
          <Route path={ROUTES.RECOVER_PASSWORD} component={RecoverPassword} />
          {showRegister() ? <Route path={ROUTES.REGISTER} component={Register} /> : null}
          <Route path={ROUTES.REGISTERGU} component={RegisterGU} />
          <PrivateRoute path={ROUTES.HOME} component={Home} />
          <PrivateRoute path={ROUTES.PROFILE} component={Profile} exact />
          <PrivateRoute path={ROUTES.PROFILE_CREATE} component={ProfileCreate} />
          <PrivateRoute
            path={ROUTES.PROFILE_EDIT}
            render={props => <ProfileEdit {...props} />}
            exact
          />
          <PrivateRoute path={ROUTES.PROFILE_EDIT_COUNTRIES} component={ProfileEditCountries} />
          <PrivateRoute path={ROUTES.PROFILE_EDIT_CATEGORIES} component={ProfileEditCategories} />
          <PrivateRoute path={ROUTES.GAME} component={Game} />
          <PrivateRoute path={ROUTES.TEST} component={Test} />
          <PrivateRoute path={ROUTES.RESULTS} component={Results} />
          <PrivateRoute path={ROUTES.RANKING} component={Ranking} />
          <PrivateRoute path={ROUTES.CONGRATULATIONS} component={Congratulations} />
          <PrivateRoute path={ROUTES.TUTORIAL} component={Tutorial} />
          <PrivateRoute path={ROUTES.ZERO_FUNDS} component={ZeroFunds} />
          <PrivateRoute path={ROUTES.GROUPS} component={Groups} exact />
          <PrivateRoute path="/groups/:id/details" component={GroupsDetails} exact />
          <PrivateRoute path={ROUTES.GROUPS_CREATE} component={GroupsCreate} exact />
          <PrivateRoute path="/groups/:id/edit" component={GroupsCreate} exact />
          <PrivateRoute path={ROUTES.COURSES} component={Courses} exact />
          <PrivateRoute path={ROUTES.COURSES_V3} component={CoursesV3} exact />
          <PrivateRoute path="/coursesv3/:id" component={CoursesV3Details} exact />
          <PrivateRoute path="/coursesv3/:id/:idCourse" component={LessonV3Details} exact />
          <PrivateRoute path={ROUTES.COURSES_V3_MY_CLASSES} component={MyClassesV3} exact />
          <PrivateRoute path="/testv3/:idCat/:idCourse" component={CoursesV3Test} exact />
          <PrivateRoute path={ROUTES.FOROS_V3} component={ForosV3} exact />
          <PrivateRoute path={ROUTES.BUDGET_V3} component={BudgetV3} exact />
          <PrivateRoute path="/budgetv3/create/:id" component={BudgetV3Create} exact />
          <PrivateRoute path="/budgetv3/details/:id" component={BudgetV3Details} exact />
          <PrivateRoute path={ROUTES.METAS_V3} component={MetasV3} exact />
          <PrivateRoute path="/metasv3/create/:id" component={MetasV3Create} exact />
          <PrivateRoute path="/metasv3/details/:id" component={MetasV3Details} exact />
          <PrivateRoute path="/courses/:id/details" component={CoursesDetails} exact />
          <PrivateRoute path="/courses/:id/test" component={CoursesTest} exact />
          <PrivateRoute path={ROUTES.BUDGET} component={Budget} exact />
          <PrivateRoute path={ROUTES.BUDGET_CREATE} component={BudgetCreate} exact />
          <PrivateRoute path={ROUTES.MISSIONS} component={Missions} exact />
          {/* <PrivateRoute path={ROUTES.WORLDS} component={Worlds} exact /> */}
          <PrivateRoute path={ROUTES.TEAM} component={Team} exact />
          <PrivateRoute path={ROUTES.ALFI_BANK} component={AlfiBank} exact />
          <PrivateRoute path="/alfi-bank-product-detail/:id" component={AlfiBankProductDetail} exact />
          <PrivateRoute path={ROUTES.ASSIGN_A_TEAM} component={AssignTeam} exact />
          <Route path={ROUTES.LOGOUT} component={Logout} />
        </Switch>
      </Suspense>
      <GoogleAnalytics />
    </Fragment>
  );
}

export default App;
